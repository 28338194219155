//-------------Importar librerias----------------------------
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import TodoDataService from '../../hooks/services'

import Typography from '@mui/material/Typography';

import Columba_Logo from '../../assets/Imagenes/columba_logo.png'
import Info_PagoEfectivo from '../../assets/Imagenes/Info_PagoEfectivo.png'
import Foother_Pago_Efectivo from '../../assets/Imagenes/Sets/FootherPagoTienda.jpeg'
import Header_Pago_Efectivo from '../../assets/Imagenes/Sets/HeaderPagoEfectivo.jpeg'


import { Box } from '@mui/material';
const fecha = new Date();
let hora = fecha.getHours() + 5;

const FichaDePago = ({ ContentRadion, RadioGroupImagen, InstruccionPagoEfectivo, Descuento, TotalServicioDespedida, ListProductos, ListCarrito, ListCarritoExtra, SubTotalExtra, SubTotal, Folio, Correo, TipoServicio, TipoDespedida, PedidoCliente }) => {
    const contentRef = useRef(null);


    const formatearComoMonedaMXN = (cantidad) => {
        const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
        });
        return formatoMonedaMXN.format(cantidad);
    };


    const [DataPagoTienda, setDataPagoTienda] = useState('')
    const [CodigoBarrra, setCodigoBarrra] = useState('')
    const [PdfPago, setPdfPago] = useState('')



    const UploadCodigoBarra = async (item) => {
        let Blob = ''
        await fetch(item)
            .then(res => res.blob())
            .then((myBlob) => {
                Blob = myBlob
            });

        let objectURL = await URL.createObjectURL(Blob);
        setCodigoBarrra(objectURL);
    }

    const GenerarPagoTienda = async () => {
        const url = "https://sandbox-api.openpay.mx/v1/msciz3cejztuuxk9q4jk/charges";
        const username = "sk_e66dc1bf28c342429c826ec5eb4e55d0";
        const password = "";

        const chargeRequest = {
            'method': 'store',
            'amount': 500,
            'currency': 'MXN', // Moneda
            'description': 'Cargo con tienda',
            'order_id': `oid-${PedidoCliente.folio}`,
            'customer': {
                'name': PedidoCliente.contactoCliente.nombre,
                'last_name': PedidoCliente.contactoCliente.apPaterno + " " + PedidoCliente.contactoCliente.apMaterno,
                'phone_number': PedidoCliente.contactoCliente.celular,
                'email': PedidoCliente.contactoCliente.email,
            },

        };

        const headers = {
            Authorization: "Basic " + btoa(username + ":" + password),
            // 'Authorization': 'Bearer ' + username,
            "Content-Type": "application/json",
        };


        await axios.post(url, chargeRequest, {
            headers: headers,
        })
            .then((response) => {
                setDataPagoTienda(response.data);
                UploadCodigoBarra(response.data.payment_method.barcode_url);
            })
            .catch((error) => {
                console.log(error);
            });
    }


    useEffect(() => {
        const GenerarPagoTienda = async () => {
            const url = "https://sandbox-api.openpay.mx/v1/msciz3cejztuuxk9q4jk/charges";
            const username = "sk_e66dc1bf28c342429c826ec5eb4e55d0";
            const password = "";

            const chargeRequest = {
                'method': 'store',
                'amount': 500,
                'currency': 'MXN', // Moneda
                'description': 'Cargo con tienda',
                'order_id': `oid-${PedidoCliente.folio}`,
                'customer': {
                    'name': PedidoCliente.contactoCliente.nombre,
                    'last_name': PedidoCliente.contactoCliente.apPaterno + " " + PedidoCliente.contactoCliente.apMaterno,
                    'phone_number': PedidoCliente.contactoCliente.celular,
                    'email': PedidoCliente.contactoCliente.email,
                },

            };

            const headers = {
                Authorization: "Basic " + btoa(username + ":" + password),
                // 'Authorization': 'Bearer ' + username,
                "Content-Type": "application/json",
            };


            await axios.post(url, chargeRequest, {
                headers: headers,
            })
                .then((response) => {
                    setDataPagoTienda(response.data);
                    UploadCodigoBarra(response.data.payment_method.barcode_url);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (DataPagoTienda === '') {
            GenerarPagoTienda();
        }

    }, []);

    const generatePdf = () => {
        const input = document.getElementById('imagen');
        let maxWidth = 210; // Ancho de la página en mm (A4)
        const maxHeight = 297; // Largo de la página en mm (A4)

        html2canvas(input)
            .then((canvas) => {
                let imgWidth = canvas.width;
                let imgHeight = canvas.height;

                // Redimensionar la imagen si el alto es mayor que el largo de una hoja A4
                if (imgHeight > maxHeight) {
                    const ratio = maxHeight / imgHeight;
                    imgWidth *= ratio;
                    imgHeight *= ratio;
                    maxWidth = imgWidth < 210 ? imgWidth : 210
                }

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();

                // Establecer márgenes en mm (A4)
                const marginLeft = 10;
                const marginTop = 10;
                const marginRight = 10;
                const marginBottom = 10;
                pdf.addImage(imgData, 'PNG', marginLeft, marginTop, maxWidth - marginLeft - marginRight, imgHeight - marginTop - marginBottom);
                pdf.setProperties({
                    title: "MyTitle.pdf"
                });
                setPdfPago(pdf)
            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };

    const OnChangePago = (e) => {
        if (e.target.value === '0') {
            window.open(`https://web.whatsapp.com/send?text=${'Folio de pago:' + DataPagoTienda.payment_method.reference}`, "_blank");
        }
        if (e.target.value === '1') { PdfPago.save('converted.pdf'); }
        if (e.target.value === '2') {
            window.open('mailto:' + "?subject=Contratacion%20de%20servicio&body=Folio%20de%20Pago:%20" + DataPagoTienda.payment_method.reference, "_blank");
            //  + encodeURIComponent(Email) + "?subject=Contratacion%20de%20servicio&body=Estoy%20interesado%20en%20contratar"
        }
        if (e.target.value === '3') {
            window.open(PdfPago.output('bloburl'));
        }

    }

    const addHoursToDate = (isoDate, hoursToAdd) => {
        // Crear un objeto Date a partir de la fecha en formato ISO
        const date = new Date(isoDate);

        // Verificar si la fecha es válida
        if (isNaN(date.getTime())) {
            throw new Error("Fecha inválida: verifica el formato");
        }

        // Sumar las horas
        date.setHours(date.getHours() + hoursToAdd);

        // Devolver la fecha en formato ISO
        return date.toISOString();
    }

    const formatDate = (isoDate) => {
        const date = new Date(isoDate);

        // Obtener el nombre del mes en español
        const months = [
            "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
            "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];

        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        // Formatear hora
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'P.M.' : 'A.M.';

        hours = hours % 12 || 12; // Convertir a formato de 12 horas

        return `${day} de ${month} de ${year} a las ${hours}:${minutes} ${period}`;
    };


    return (
        <>
            <Typography variant='h4' sx={{ fontSize: '25px', fontWeight: '600 !important', margin: '0px' }} className="subtitulo"> Instrucciones para realizar el pago </Typography>

            <div id="imagen" ref={contentRef}>
                {/* <InstruccionPagoEfectivo /> */}

                {/* <Box sx={{ display: 'flex', marginTop: '5%', justifyContent: 'center' }}>
                    <div style={{ margin: '0% 15px', borderRadius: '10px', border: '1px solid', borderColor: 'black', boxShadow: PdfPago === '' ? 'none' : '5px 5px 0px 3px #D14081' }} className="row">
                        <div className="col-sm-12" style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <img width='13%' src={Columba_Logo} alt='Columba_Logo' />  <Typography sx={{ fontWeight: 'bold !important' }}> Caducidad: {hora >= 24 ? hora - 24 : hora + ':' + fecha.getMinutes()} {fecha.toLocaleDateString()} </Typography>
                        </div>
                        <div className="row" style={{ margin: '0%', padding: '0%' }}>
                            <div className="col-md-5">
                                <div style={{ marginTop: '5%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography display="inline">Numero de folio:</Typography> <Typography display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important', fontSize: '20px' }}>{PedidoCliente.folio}</Typography>
                                </div>
                                <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'flex-start' }}>
                                    <Typography display="inline">Correo del comprador: {Correo}</Typography>
                                </div>

                                <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography display="inline">Total a pagar:</Typography> <Typography display="inline" sx={{ fontSize: '20px' }}>{formatearComoMonedaMXN(SubTotalExtra + SubTotal - Descuento)}</Typography>
                                </div>

                                <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography sx={{ fontWeight: 'bold !important' }} display="inline">Anticipo de:</Typography> <Typography display="inline" sx={{ fontWeight: 'bold !important', fontSize: '20px' }}>{formatearComoMonedaMXN(5000)}</Typography>
                                </div>

                                <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Typography display="inline">Restan:</Typography> <Typography display="inline" sx={{ fontSize: '20px' }}>{formatearComoMonedaMXN(SubTotalExtra + SubTotal - Descuento - 5000)}</Typography>
                                </div>

                                <div style={{ marginTop: '2%', display: 'flex', justifyContent: 'center' }}>
                                    <Box style={{ display: 'flex', flexDirection: 'column' }} >
                                        <Typography mb='5px' display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important', fontSize: '24px' }}>Pago en efectivo</Typography>

                                        {
                                            DataPagoTienda !== ''
                                                ?
                                                <>
                                                    <img alt='Codogo de barra' onLoad={generatePdf} src={CodigoBarrra} />
                                                    <Typography textAlign='center'>{DataPagoTienda.payment_method.reference}</Typography>
                                                </>

                                                :
                                                <></>
                                        }
                                    </Box>

                                </div>

                            </div>
                            <div className="col-md-7">

                                <div className="col">
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <img style={{ margin: '3px 0px' }} width='100%' src={Info_PagoEfectivo} alt='Info_PagoEfectivo' />
                                    </div>
                                </div>
                                <div style={{ margin: '2% 0% 0% 0%', padding: '0%', borderRadius: '20px', backgroundColor: '#f4f4f4', border: '3px solid', borderColor: '#D14081' }} className="col-md-12 row">
                                    <div style={{ marginTop: '10px' }} className='col-md-9'>
                                        <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Servicio:</Typography> <Typography display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important' }}>{TipoServicio}, {TipoDespedida}</Typography>
                                    </div>
                                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'flex-end' }} className='col-md-3'>
                                        <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(TotalServicioDespedida)}</Typography>
                                    </div>
                                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
                                        <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Productos</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(SubTotal - TotalServicioDespedida)}</Typography>
                                    </div>

                                    {
                                        ListCarrito.map((element, index) => {
                                            let cantidadASumar = 0;
                                            const productoEncontrado = ListProductos.find((producto) => (producto.producto === element.producto && producto.grupo === element.grupo));
                                            if (productoEncontrado && productoEncontrado.valor_default === 1) {
                                            } else {
                                                const productosEnMismoGrupo = ListProductos.filter((producto) => (producto.grupo === element.grupo && producto.valor_default === 1));
                                                cantidadASumar = element.precio_venta - productosEnMismoGrupo[0].precio_venta;
                                            }
                                            return (
                                                <div div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} key={index}>
                                                    <Typography display="inline" sx={{ fontSize: '12px' }}> {element.nombre_prodcto}</Typography>   <Typography display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(cantidadASumar !== 0 ? cantidadASumar : 0)}</Typography>
                                                </div>
                                            )
                                        })
                                    }


                                    <div style={{ marginBottom: '5px', marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
                                        <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Extras</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>{formatearComoMonedaMXN(SubTotalExtra)}</Typography>

                                    </div>
                                    {
                                        ListCarritoExtra.map((element, index) => (
                                            <div className='col-md-12' style={{ margin: '0% 0%', display: 'flex', justifyContent: 'space-between' }} key={index}>
                                                <Typography display="inline" sx={{ fontSize: '12px' }}>{element.cantidad}  {element.nombre_prodcto}</Typography>   <Typography display="inline" sx={{ fontSize: '12px' }}>{formatearComoMonedaMXN(element.precio_venta * element.cantidad)}</Typography>
                                            </div>
                                        ))
                                    }

                                    <div style={{ marginBottom: '5px', marginTop: '10px', display: 'flex', justifyContent: 'space-between' }} className='col-md-12'>
                                        <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>Descuento</Typography>   <Typography display="inline" sx={{ fontWeight: 'bold !important' }}>-{formatearComoMonedaMXN(Descuento)}</Typography>
                                    </div>

                                    <div style={{ marginTop: '10px' }}></div>
                                </div>

                            </div>
                        </div>
                        <div className="col-md-12" style={{ margin: '1% 0%' }}>
                            <Typography textAlign='center' sx={{ margin: '0% 5%', fontWeight: 'bold !important' }}>
                                <Typography display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important' }}>IMPORTANTE:</Typography>  El pago debe de realizarse dentro de las proximas <Typography display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important' }}>5 HORAS</Typography>, de lo contrario se cancelara el proceso. Para mas informacion comunicate al  <Typography display="inline" sx={{ color: '#D14081', fontWeight: 'bold !important' }}>000-000-000</Typography>
                            </Typography>
                        </div>
                    </div>
                </Box> */}

                <img src={Header_Pago_Efectivo} alt='Header_Pago_Efectivo' width='100%' style={{ margin: '20px 0px' }} />
                <div className='mt-4 row'>
                    <div className='mb-2 col-md-6' style={{ borderRight: 'solid 10px white', background: '#fae2a5' }}>
                        <div style={{ margin: '20px 10px 10px 13% ' }}>
                            <Typography textAlign='left' mt='12px' mb='12px' fontSize='28px' >Fecha límite de pago</Typography>
                            <Typography>{formatDate(addHoursToDate(PedidoCliente.fecha, 5))}</Typography>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <div style={{ width: '80%', background: 'white' }}>
                                    <img width='100%' height='50px' alt='Codogo de barra' onLoad={generatePdf} src={CodigoBarrra} />
                                    <Typography textAlign='center' fontSize='10px' >Columba funerales</Typography>
                                </div>

                            </div>
                            {
                                DataPagoTienda !== ''
                                    ?
                                    <Typography textAlign='center' mt='12px' fontSize='12px' >{DataPagoTienda.payment_method.reference}</Typography>
                                    :
                                    <></>
                            }
                            <Typography textAlign='center' fontSize='12px' >En caso de que el escaner  no sea capaz de leer el código
                                de barras, escribir la referencia como se muestra. </Typography>
                        </div>
                    </div>


                    <div className='mb-2 col-md-6 ' style={{ borderLeft: 'solid 10px white', background: '#b44b87', borderTopLeftRadius: '100px' }}>
                        <Typography textAlign='left' ml='50px' mt='30px' color='white' fontSize='16px' >Total a Pagar</Typography>
                        <Typography textAlign='left' ml='50px' mb='12px' color='white' fontSize='40px' >{formatearComoMonedaMXN(SubTotalExtra + SubTotal - Descuento - 5000)} MXN</Typography>
                        <Typography textAlign='center' mr='10px' ml='50px' mb='30px' color='white' fontSize='12px' >
                            La comisión por recepción del pago varia de acuerdo a los
                            términos y condiciones que cada cadena comercial establezca.
                        </Typography>
                    </div>
                </div>

                <Typography textAlign='left' fontWeight='500 !important' padding='0px 12px' margin='12px 5%' fontSize='28px' > Detalles de la compra</Typography>

                <div className='mb-4 mt-4 row'>
                    <div className="col-md-4" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' ml='5px' mt='12px' mb='12px' fontSize='16px' >Descripción</Typography>
                    </div>
                    <div className="col-md-8" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' mt='12px' mb='12px' fontSize='16px' > Descripción de la compra realizada</Typography>
                    </div>

                    <div className="col-md-4" style={{ background: ' #fae2a5 ', border: 'solid 2px white' }}>
                        <Typography textAlign='left' ml='5px' mt='12px' mb='12px' fontSize='16px' >Fecha y hora</Typography>
                    </div>
                    <div className="col-md-8" style={{ background: ' #fae2a5 ', border: 'solid 2px white' }}>
                        <Typography textAlign='left' mt='12px' mb='12px' fontSize='16px' > {formatDate(PedidoCliente.fecha)}</Typography>
                    </div>

                    <div className="col-md-4" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' ml='5px' mt='12px' mb='12px' fontSize='16px' >Correo del cliente</Typography>
                    </div>
                    <div className="col-md-8" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' mt='12px' mb='12px' fontSize='16px' > {Correo}</Typography>
                    </div>
                </div>


                <div className=' mt-4 row' style={{ margin: '0px 5%' }}>
                    <div className="col-md-6">
                        <Typography textAlign='left' fontWeight='500 !important' mt='12px' mb='12px' fontSize='28px' >Como realizar el pago</Typography>

                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' > 1. Acude a cualquier tienda afiliada</Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            2. Entrega al cajero el código de barras y mencione que
                            realizaras un pago de servicio Paynet
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            3. Realizar el pago en efectivo por $14,800.00 MXN
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            4. Conserva el ticket para cualquier aclaración
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            Si tiene dudas comunícate a COLUMBA al teléfono
                            {TodoDataService.GetNumberCall()} o al correo {TodoDataService.GetEmailCall()}
                        </Typography>
                    </div>
                    <div className="col-md-6">
                        <Typography textAlign='left' fontWeight='500 !important' mt='12px' mb='12px' fontSize='28px' > Instrucciones para el cajero</Typography>

                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            1. Ingresar al menú Pago de Servicios
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            2. Seleccionar Paynet
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            3. Escanear el código de barras o ingresar el núm. de
                            referencia
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            4. Ingresa la cantidad total a pagar
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            5. Cobrar al cliente el monto más comisión
                        </Typography>
                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            6. Confirmar la transacción y entregar el ticket al cliente
                        </Typography>

                        <Typography textAlign='left' mt='10px' mb='10px' fontSize='12px' >
                            Para cualquier duda sobre como cobrar, por favor llamar al teléfono
                            +52 {TodoDataService.GetNumberCall()} en un horario de 8 am a 9 pm de lunes a domingo
                        </Typography>
                    </div>
                </div>
                <img src={Foother_Pago_Efectivo} alt='Foother_Pago_Efectivo' width='100%' />
            </div>
            <RadioGroupImagen Origen='Row' Name='TipoPdf' WidthImagen='50%' Width='md' OnChange={OnChangePago} JustifyContent='space-between' Content={ContentRadion} />
        </>
    );
}

export default FichaDePago;

