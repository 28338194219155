import React, { useState, useEffect } from 'react';

// Importar Componentes de Terceros
import PaymentsIcon from '@mui/icons-material/Payments';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CompareArrowsSharpIcon from '@mui/icons-material/CompareArrowsSharp';
import { Box, Typography } from '@mui/material';

// Importar ComponentesPersonalizados
import RadioGroupImagen from "../../../components/RadioGroup-Imagen";
import InstruccionPagoEfectivo from '../../../components/Instrucciones_PagoEfectivo';
import FichaDePago from '../../../components/Ficha_DePago';
import ModalBaseMui from '../../../components/Modal_Mui';
import OpenPayBBVA from '../../../components/OpenPay_BBVA';
import PagoTransferencia from '../../../components/Transferencia';
import AlertMui from '../../../components/Alert_Mui';

// Iportar Archivos estaticos
import TransferenciaPasivo from "../../../assets/Imagenes/Sets/FORMAS DE PAGO/TRANSFERENCIA.png"
import EfectivoPasivo from "../../../assets/Imagenes/Sets/FORMAS DE PAGO/EFECTIVOPASIVO.png"
import CreditoDebitoPasivo from "../../../assets/Imagenes/Sets/FORMAS DE PAGO/CREDITODEBITOPASIVO.png"

import TransferenciaActio from "../../../assets/Imagenes/Sets/FORMAS DE PAGO/TRANSFERENCIAACTIVO.png"
import EfectivoActivo from "../../../assets/Imagenes/Sets/FORMAS DE PAGO/EFECTIVOACTIVO.png"
import CreditoDebitoActivo from "../../../assets/Imagenes/Sets/FORMAS DE PAGO/CREDITODEBITOACTIVO.png"

import Efectivo_Info from '../../../assets/Imagenes/Sets/efectivo.jpg'
import CreditoDebito_Info from '../../../assets/Imagenes/Sets/tarjeta.jpg'

import StepWhatsApp from '../../../assets/Imagenes/Sets/Stepss/Grupo Whatsapp.png'
import StepPdf from '../../../assets/Imagenes/Sets/Stepss/Grupo Descargar.png'
import StepCorreo from '../../../assets/Imagenes/Sets/Stepss/Grupo email.png'
import StepImprimir from '../../../assets/Imagenes/Sets/Stepss/Grupo Imprimir.png'

import StepWhatsApp_B from '../../../assets/Imagenes/Sets/Stepss/Grupo Whatsapp-B.png'
import StepPdf_B from '../../../assets/Imagenes/Sets/Stepss/Grupo Descargar-B.png'
import StepCorreo_B from '../../../assets/Imagenes/Sets/Stepss/Grupo email-B.png'
import StepImprimir_B from '../../../assets/Imagenes/Sets/Stepss/Grupo Imprimir-B.png'

// Componente principal del paso de pago
const StepPago = ({EnviarMensaje, Total, OnClickHandleNext, FormFinado, FormResponsable, OnClickGuardar, DataValidarCupon, OnClickValidarCupon, TotalServicioDespedida, TipoServicio, TipoDespedida, CorreoResponsable, SubTotalExtra, SubTotal, ListCarritoExtra, ListProductos, ListCarrito, FormPago, OnChange, Style, SwitchHandleChange, HandleChangeSingle, Token }) => {
    // Estado para confirmar el pago
    const [Confirmar, setConfirmar] = useState(false);
    const [ValidarCupon, setValidarCupon] = useState(false);


    // Contenido para los botones de radio de opciones de pago
    const ContentRadion = [{ Id: 0, IconPasivo: CreditoDebitoPasivo, IconActivo: CreditoDebitoActivo, Disabled: false }, { Id: 1, IconPasivo: EfectivoPasivo, IconActivo: EfectivoActivo, Disabled: false }, { Id: 2, IconPasivo: TransferenciaPasivo, IconActivo: TransferenciaActio, Disabled: false }]

    // Contenido para los botones de radio de opciones de entrega de comprobante
    const ContentRadion2 = [{ Id: 0, IconPasivo: StepWhatsApp, IconActivo: StepWhatsApp_B, Disabled: false }, { Id: 1, IconPasivo: StepPdf, IconActivo: StepPdf_B, Disabled: false }, { Id: 2, IconPasivo: StepCorreo, IconActivo: StepCorreo_B, Disabled: false }, { Id: 3, IconPasivo: StepImprimir, IconActivo: StepImprimir_B, Disabled: false }]

    // Efecto secundario para cambiar el estado al inicio del componente
    useEffect(() => {
        SwitchHandleChange('FormPago');
    }, []);

    // Manejador de cambios en el formulario de pago
    const HandleChange = (e) => {
        // Si no se ha ingresado el pedido del cliente
        if (FormPago.PedidoCliente === '') {
            // Crear una nueva lista de productos extra sin combo
            let ListCarritoExtraNew = ListCarritoExtra.map((element) => ({ cantidad: element.cantidad, combo: '', grupo: '', nombre_prodcto: element.nombre_prodcto, notas: '', precio_venta: element.precio_venta, producto: element.producto }))
            // Datos del cliente responsable del pedido
            let Responsable = {
                codEstab: '2', codCte: FormPago.KeyCliente, sucursalCliente: '', pedidoDelCliente: '', notas: '', usuario: '1', vendedor: '', tipoAtencion: '', canalVenta: '', cuponDescuento: FormPago.CuponValido === true ? FormPago.FolioCupon : '',
                productos: [...ListCarrito, ...ListCarritoExtraNew],
                contactoCliente: {
                    contactoCliente: '',
                    nombre: FormResponsable.Nombre,
                    apPaterno: FormResponsable.ApellidoPaterno,
                    apMaterno: FormResponsable.ApellidoMaterno,
                    nombreCompleto: FormResponsable.Nombre + ' ' + FormResponsable.ApellidoPaterno + ' ' + FormResponsable.ApellidoMaterno,
                    telefono: FormResponsable.TelefonoPrimario,
                    celular: FormResponsable.TelefonoSecundario,
                    email: FormResponsable.Email
                }
            }
            // Datos del finado
            let Finado = {
                nombre: FormFinado.NombreFinado, apPaterno: FormFinado.ApellidoPaternoFinado, apMaterno: FormFinado.ApellidoMaternoFinado, codEstab: "2", sexo: FormFinado.Gernero, lugarDefuncionId: "1",
                lugarDefuncion: {
                    tipoLugarDefuncion: "NR",
                    lugarDefuncioId: "",
                    nombre: FormFinado.NombreLugar,
                    domicilio: FormFinado.Direccion,
                    municipio: FormFinado.Municipio,
                    calle: FormFinado.Direccion,
                    numInt: "",
                    numExt: "",
                    colonia: FormFinado.Colonia,
                    delegacion: "",
                    cp: FormFinado.CodigoPostal,
                    referencias: FormFinado.Referencias,
                    asentamiento: FormFinado.Colonia
                }
            }
            // Llamar a la función para guardar los datos
            OnClickGuardar({ Token: Token, DataResponsable: Responsable, DataFinado: Finado, Close: () => (OnChange(e)), Refresh: HandleChangeSingle })
        }
        else {
            // Si ya se ingresó el pedido del cliente, simplemente aplicar el cambio
            OnChange(e);
        }
    }

    React.useEffect(() => {

        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };

        scrollToTop();
    }, []);


    // Retornar la interfaz del componente
    return (
        <div style={{ margin: '0px 0px' }} className="row">
            {/* Modal para mostrar errores */}
            {
                FormPago.Error.length > 0
                    ?
                    <ModalBaseMui Open={FormPago.Error.length > 0 ? true : false} Title='Completa la siguiente información!' MaxWidth='sm' FullWidth={true} FullScreen={false} OnClickClose={() => (HandleChangeSingle('FormPago', 'Error', []))}
                        ContentModal={
                            FormPago.Error.map((item, index) => (
                                <h5 key={index} style={{ textAlign: 'center' }}>{item}</h5>
                            ))
                        }
                        Action={<Button sx={{ color: '#d63384' }} onClick={() => (HandleChangeSingle('FormPago', 'Error', []))}>Ok</Button>}
                    />
                    :
                    <></>
            }

            {/* Sección para ingresar el código de cupón */}
            {
                FormPago.PedidoCliente === ''
                    ?
                    <>
                        <div className="col-md-12" style={{ padding: '0px', marginBottom: '15px' }}>
                            <Typography variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }} >Ingresa cupón de descuento</Typography>
                        </div>
                        <div className="col-sm-8" style={{ marginBottom: '10px' }}>
                            <input name='FolioCupon' type="text" onChange={OnChange} className="form-control" id="inCupon" placeholder="Cupón" />
                            {
                                FormPago.Descuento !== 0 && ValidarCupon === true
                                    ? <Box mt='10px'><AlertMui Text='Descuento realizado' OnChange={() => (setValidarCupon(false))} Time={5000} /> </Box>
                                    : <></>
                            }

                        </div>
                        <div className="col-sm-4 " style={{ marginBottom: '10px', textAlign: 'left' }}>
                            <Style style={{ textAlign: 'end' }} >
                                <button disabled={FormPago.FolioCupon === '' ? true : ''} onClick={() => { OnClickValidarCupon(Object.assign(DataValidarCupon, { Total: Total })); setValidarCupon(true); }} type="button" className="btn btn-primary btn btnAmarilloChicos validar" id="btnCupon"> Validar</button>
                            </Style>
                        </div>
                    </>
                    :
                    <></>
            }

            {/* Sección para seleccionar método de pago */}
            <div className="col-sm-12" style={{ padding: '0px' }}>
                {
                    Confirmar === false
                        ? <Typography variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }} > {Confirmar === true ? 'Instrucciones para realizar el pago' : 'Selecciona el método de pago'}</Typography>
                        :
                        <></>
                }

                {
                    FormPago.Tipo === ''
                        ?
                        <RadioGroupImagen WidthImagen='95%' Origen='FormaPago' DefaultValue={FormPago.Tipo} Name='Tipo' Width='lg' OnChange={HandleChange} JustifyContent='space-between' Content={ContentRadion} />
                        :
                        <>
                            {
                                FormPago.Tipo === '0'
                                    ?
                                    Confirmar === false
                                        ?
                                        <>
                                            {/* Botón de tarjeta de crédito/débito */}
                                            {/* <Button disabled sx={{ width: '200px', background: '#FFEC00', fontWeight: 'bold', fontSize: '16px', fontFamily: 'Itaca', color: 'black', borderColor: '#FFEC00', borderRadius: '5px', '&:hover': { background: 'white', borderColor: '#FFEC00', color: '#374957' } }} variant="outlined" startIcon={<CreditCardIcon />}> Crédito / Débito</Button> */}
                                            <Box mt='7px' sx={{ display: 'flex' }}>
                                                <Box sx={{ borderRadius: '5px', backgroundColor: '#FFEC00', width: '200px', padding: '10px', display: 'flex', justifyContent: 'space-evenly' }}> <CreditCardIcon sx={{ color: '#374957' }} /> <Typography color='#374957'>Crédito / Débito</Typography></Box>
                                                <Link onClick={() => (HandleChangeSingle('FormPago', 'Tipo', ''))} align="left" color='#D14081' sx={{ marginLeft: '20px', fontSize: '15px', fontFamily: 'Itaca ', '&:hover': { color: '#D95F96' } }} component="button" variant="body2" >
                                                    Cambiar
                                                </Link>
                                            </Box>


                                            {/* Información sobre tarjeta de crédito/débito */}
                                            <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'center' }}>
                                                <img width='100%' src={CreditoDebito_Info} alt='info' />
                                            </div>
                                            {/* Botón para continuar */}
                                            <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'flex-end' }}>
                                                <Style>
                                                    <button onClick={() => (setConfirmar(true))} type="button" className="btn btn-primary btn btnAmarilloChicos" id="btnCupon"> Continuar</button>
                                                </Style>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <Typography mb='7px' variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }} > Instrucciones para realizar el pago</Typography>

                                            {/* Componente para realizar pago con OpenPay BBVA */}
                                            <OpenPayBBVA EnviarMensaje={EnviarMensaje} Token={Token} Style={Style} PedidoCliente={FormPago.PedidoCliente} OnClickHandleNext={OnClickHandleNext} CambiarFormaPago={() => { HandleChangeSingle('FormPago', 'Tipo', ''); setConfirmar(false); }} />
                                        </>
                                    :
                                    FormPago.Tipo === '1'
                                        ?
                                        Confirmar === false
                                            ?
                                            <>
                                                {/* Botón de pago en efectivo */}

                                                <Box mt='7px' sx={{ display: 'flex' }}>
                                                    <Box sx={{ borderRadius: '5px', backgroundColor: '#FFEC00', width: '200px', padding: '10px', display: 'flex', justifyContent: 'space-evenly' }}> <PaymentsIcon sx={{ color: '#374957' }} /> <Typography color='#374957'>Efectivo</Typography></Box>
                                                    <Link onClick={() => (HandleChangeSingle('FormPago', 'Tipo', ''))} align="left" color='#D14081' sx={{ marginLeft: '20px', fontSize: '15px', fontFamily: 'Itaca ', '&:hover': { color: '#D95F96' } }} component="button" variant="body2" >
                                                        Cambiar
                                                    </Link>
                                                </Box>

                                                {/* Información sobre pago en efectivo */}
                                                <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'center' }}>
                                                    <img width='100%' src={Efectivo_Info} alt='info' />
                                                </div>
                                                {/* Botón para continuar */}
                                                <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'flex-end' }}>
                                                    <Style>
                                                        <button onClick={() => (setConfirmar(true))} type="button" className="btn btn-primary btn btnAmarilloChicos" id="btnCupon"> Continuar</button>
                                                    </Style>
                                                </div>
                                            </>
                                            :
                                            <>
                                                {/* Sección para instrucciones de pago en efectivo */}
                                                {/* <InstruccionPagoEfectivo /> */}

                                                <FichaDePago ContentRadion={ContentRadion2} RadioGroupImagen={RadioGroupImagen} InstruccionPagoEfectivo={InstruccionPagoEfectivo} PedidoCliente={FormPago.PedidoCliente} Descuento={FormPago.Descuento} TotalServicioDespedida={TotalServicioDespedida} ListProductos={ListProductos} ListCarrito={ListCarrito} ListCarritoExtra={ListCarritoExtra} SubTotalExtra={SubTotalExtra} SubTotal={SubTotal} Correo={CorreoResponsable} TipoServicio={TipoServicio} TipoDespedida={TipoDespedida} />

                                                {/* Botón para confirmar */}
                                                <div style={{ display: 'flex', marginTop: '16px', justifyContent: 'flex-end' }}>
                                                    <Box width={{ xs: '100%', sm: '42%' }}>
                                                        <Style>
                                                            <button onClick={() => (OnClickHandleNext('StepLenght'))} type="button" className="btn btn-primary btn btnAmarilloChicos" id="btnCupon"> Confirmar</button>
                                                        </Style>
                                                    </Box>

                                                </div>
                                            </>
                                        :
                                        FormPago.Tipo === '2'
                                            ?


                                            Confirmar === false
                                                ?
                                                <Box>
                                                    {/* Enlace para cambiar el método de pago */}
                                                    <Box mt='7px' sx={{ display: 'flex' }}>
                                                        <Box sx={{ borderRadius: '5px', backgroundColor: '#FFEC00', width: '200px', padding: '10px', display: 'flex', justifyContent: 'space-evenly' }}> <CompareArrowsSharpIcon sx={{ color: '#374957' }} /> <Typography color='#374957'>Trasferencia</Typography></Box>
                                                        <Link onClick={() => (HandleChangeSingle('FormPago', 'Tipo', ''))} align="left" color='#D14081' sx={{ marginLeft: '20px', fontSize: '15px', fontFamily: 'Itaca ', '&:hover': { color: '#D95F96' } }} component="button" variant="body2" >
                                                            Cambiar
                                                        </Link>
                                                    </Box>


                                                    {/* Información sobre tarjeta de crédito/débito */}
                                                    <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'center' }}>
                                                        <img width='100%' src={CreditoDebito_Info} alt='info' />
                                                    </div>
                                                    {/* Botón para continuar */}
                                                    <div style={{ display: 'flex', marginTop: '10%', justifyContent: 'flex-end' }}>
                                                        <Style>
                                                            <button onClick={() => (setConfirmar(true))} type="button" className="btn btn-primary btn btnAmarilloChicos" id="btnCupon"> Continuar</button>
                                                        </Style>
                                                    </div>
                                                </Box>
                                                :
                                                <Box>
                                                    <PagoTransferencia ContentRadion={ContentRadion2} RadioGroupImagen={RadioGroupImagen} PedidoCliente={FormPago.PedidoCliente} />


                                                    <div style={{ width: '250px', display: 'flex', marginTop: '10%', justifyContent: 'flex-end' }}>
                                                        <Style>
                                                            <button onClick={() => (OnClickHandleNext('StepLenght'))} type="button" className="btn btn-primary btn btnAmarilloChicos" id="btnCupon"> Confirmar</button>
                                                        </Style>
                                                    </div>
                                                </Box>
                                            :
                                            <></>
                            }
                        </>
                }
            </div>

            <Box marginBottom={{ xs: '130px', sm: '0px' }}></Box>
        </div>

    );

}

// Exportar el componente principal del paso de pago

export default StepPago;