//-------------Importar librerias----------------------------
import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import TodoDataService from '../../hooks/services'


// -----------Importar Componentes de Terceros------------------
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/system/Unstable_Grid';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

// -----------Importar Componentes Personalizados------------------
import InstruccionPagoTransferencia from '../Instrucciones_PagoTransferencia';

// -----------Importar Archivos estatiscos------------------
import BBVA from '../../assets/Imagenes/Sets/BBVA.png'
import SANTANDER from '../../assets/Imagenes/Sets/Santander.png'
import CITIBANAMEX from '../../assets/Imagenes/Sets/Citibanamex.png'
import Header_Transferencia from '../../assets/Imagenes/Sets/HeaderTransferencia.jpeg';
import Foother_Transferencia from '../../assets/Imagenes/Sets/FootherTransferencia.jpeg';
import { getSuggestedQuery } from '@testing-library/react';


const PagoTransferencia = ({ ContentRadion, RadioGroupImagen, PedidoCliente }) => {
    const [DataTrasferencia, setDataTrasferencia] = useState('')
    const [PdfPago, setPdfPago] = useState('')
    const contentRef = useRef(null);

    useEffect(() => {
        const GenerarTransferencia = async () => {
            const url = "https://sandbox-api.openpay.mx/v1/msciz3cejztuuxk9q4jk/charges";
            const username = "sk_e66dc1bf28c342429c826ec5eb4e55d0";
            const password = "";

            const chargeRequest = {
                'method': 'bank_account',
                'amount': 100.00, // Cantidad a cobrar en centavos (100 pesos)
                'currency': 'MXN', // Moneda
                'description': 'Cargo con banco',
                'order_id': `oid-${PedidoCliente.folio}`,
                'customer': {
                    'name': PedidoCliente.contactoCliente.nombre,
                    'last_name': PedidoCliente.contactoCliente.apPaterno + " " + PedidoCliente.contactoCliente.apMaterno,
                    'phone_number': PedidoCliente.contactoCliente.celular,
                    'email': PedidoCliente.contactoCliente.email,
                },
            };

            const headers = {
                Authorization: "Basic " + btoa(username + ":" + password),
                // 'Authorization': 'Bearer ' + username,
                "Content-Type": "application/json",
            };


            await axios.post(url, chargeRequest, {
                headers: headers,
            })
                .then((response) => {
                    setDataTrasferencia(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }

        if (DataTrasferencia === '') {
            GenerarTransferencia();
        }

    }, []);

    const formatearComoMonedaMXN = (cantidad) => {
        const formatoMonedaMXN = new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
        });
        return formatoMonedaMXN.format(cantidad);
    };

    const copiarContenido = async (item) => {
        try {
            await navigator.clipboard.writeText(item);
        } catch (err) {
            console.error('Error al copiar: ', err);
        }
    };



    const generatePdf = () => {
        const input = document.getElementById('imagen');
        let maxWidth = 210; // Ancho de la página en mm (A4)
        const maxHeight = 297; // Largo de la página en mm (A4)

        html2canvas(input)
            .then((canvas) => {
                let imgWidth = canvas.width;
                let imgHeight = canvas.height;

                // Redimensionar la imagen si el alto es mayor que el largo de una hoja A4
                if (imgHeight > maxHeight) {
                    const ratio = maxHeight / imgHeight;
                    imgWidth *= ratio;
                    imgHeight *= ratio;
                    maxWidth = imgWidth < 210 ? imgWidth : 210
                }

                const imgData = canvas.toDataURL('image/png');
                const pdf = new jsPDF();

                // Establecer márgenes en mm (A4)
                const marginLeft = 10;
                const marginTop = 10;
                const marginRight = 10;
                const marginBottom = 10;


                pdf.addImage(imgData, 'PNG', marginLeft, marginTop, maxWidth , imgHeight - marginTop - marginBottom);

                pdf.setProperties({
                    title: "MyTitle.pdf"
                });
                setPdfPago(pdf)

            })
            .catch((error) => {
                console.error('Error generating PDF:', error);
            });
    };


    const OnChangePago = (e) => {
        if (e.target.value === '0') {
            window.open(`https://web.whatsapp.com/send?text=${'Clave Interbancaria:' + DataTrasferencia.payment_method.name}`, "_blank");
        }
        if (e.target.value === '1') { PdfPago.save('converted.pdf'); }
        if (e.target.value === '2') {
            window.open('mailto:' + "?subject=Contratacion%20de%20servicio&body=Clave%20Interbancaria:%20" + DataTrasferencia.payment_method.name, "_blank");
            //  + encodeURIComponent(Email) + "?subject=Contratacion%20de%20servicio&body=Estoy%20interesado%20en%20contratar"
        }
        if (e.target.value === '3') {
            window.open(PdfPago.output('bloburl'));
        }

    }

    const addHoursToDate = (isoDate, hoursToAdd) => {
        // Crear un objeto Date a partir de la fecha en formato ISO
        const date = new Date(isoDate);
    
        // Verificar si la fecha es válida
        if (isNaN(date.getTime())) {
            throw new Error("Fecha inválida: verifica el formato");
        }
    
        // Sumar las horas
        date.setHours(date.getHours() + hoursToAdd);
    
        // Devolver la fecha en formato ISO
        return date.toISOString();
    }

      const formatDate = (isoDate) => {
        const date = new Date(isoDate);
        
        // Obtener el nombre del mes en español
        const months = [
          "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
          "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
        ];
      
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();
      
        // Formatear hora
        let hours = date.getHours();
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const period = hours >= 12 ? 'P.M.' : 'A.M.';
      
        hours = hours % 12 || 12; // Convertir a formato de 12 horas
      
        return `${day} de ${month} de ${year} a las ${hours}:${minutes} ${period}`;
      };
   

      console.log(DataTrasferencia);

    return (

        <Box>
                <Typography variant='h4' sx={{ color: '#D14081', fontSize: '25px', fontWeight: '600 !important' }}> Instrucciones para realizar el pago</Typography>

            <div id="imagen" ref={contentRef} >
{/* 

                <InstruccionPagoTransferencia />
                {
                    DataTrasferencia !== ''
                        ?
                        < Box sx={{ height: { xs: '100%', sm: '258px' }, padding: '0px !important', margin: '24px 15px 24px 15px !important', border: 'solid 2px grey', borderRadius: '10px', boxShadow: PdfPago === '' ? 'none' : '5px 5px 0px 3px #D14081' }}>

                            <Grid container sx={{ margin: '10px 0px' }} >
                                <Grid xs={12} sm={2}></Grid>
                                <Grid container rowSpacing={3} xs={12} textAlign='center' sm={8}>
                                    <Grid xs={12} sm={5}><Typography >Banco</Typography></Grid>
                                    <Grid xs={12} sm={5} ><Typography >Clabe interbancaria</Typography></Grid>
                                    <Grid xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <img alt='LOGO SANTANDER' src={SANTANDER} />
                                    </Grid>
                                    <Grid xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', sm: 'space-between' } }}>
                                        <Typography>{DataTrasferencia.payment_method.name}</Typography>
                                        <IconButton onClick={() => (copiarContenido(DataTrasferencia.payment_method.name))} sx={{ backgroundColor: '#D14081', color: 'white' }}><ContentCopyIcon /></IconButton>
                                    </Grid>
                                    <Grid xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <img alt='LOGO BBVA' src={BBVA} />
                                    </Grid>
                                    <Grid xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', sm: 'space-between' } }}>
                                        <Typography>{DataTrasferencia.payment_method.name}</Typography>
                                        <IconButton onClick={() => (copiarContenido(DataTrasferencia.payment_method.name))} sx={{ backgroundColor: '#D14081', color: 'white' }}><ContentCopyIcon /></IconButton>
                                    </Grid>
                                    <Grid xs={12} sm={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'center', sm: 'flex-start' } }}>
                                        <img alt='LOGO CITIBANAMEX' onLoad={generatePdf} src={CITIBANAMEX} />
                                    </Grid>
                                    <Grid xs={12} sm={7} sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', sm: 'space-between' } }}>
                                        <Typography>{DataTrasferencia.payment_method.name}</Typography>
                                        <IconButton onClick={() => (copiarContenido(DataTrasferencia.payment_method.name))} sx={{ backgroundColor: '#D14081', color: 'white' }} ><ContentCopyIcon /></IconButton>
                                    </Grid>
                                </Grid>


                                <Grid xs={12} sm={2}></Grid>
                            </Grid>
                        </Box>

                        :
                        <></>
                } */}

                <img src={Header_Transferencia} alt='Header_Transferencia' width='100%' />

                <div className=' mb-4 row' style={{margin:'100px  0px 0px 0%'}}>
                    <div className='mb-2 col-md-6' style={{ borderRight: 'solid 10px white', background: 'white' }}>
                        <div style={{ margin: '20px 10px 10px 13% ' }}>
                            <Typography textAlign='left' mt='12px' mb='0px' fontSize='28px' >Fecha límite de pago</Typography>
                            <Typography>{formatDate(addHoursToDate(PedidoCliente.fecha,5))}</Typography>
                           
                            <Typography mt='24px' textAlign='left' fontSize='24px' >Beneficiario:</Typography>
                            <Typography textAlign='left' fontSize='24px' >COLUMBA</Typography>

                        </div>
                    </div>


                    <div className='mb-2 col-md-6 ' style={{ borderLeft: 'solid 10px white', background: '#b44b87', borderTopLeftRadius: '100px' }}>
                        <Typography textAlign='left' ml='50px' mt='48px' color='white' fontSize='16px' >Total a pagar / MXN</Typography>
                        <Typography textAlign='left' ml='50px' mb='50px' color='white' fontSize='40px' >{formatearComoMonedaMXN(PedidoCliente.total)}  MXN</Typography>
                       
                    </div>
                </div>
                <Typography textAlign='left' fontWeight='500 !important' padding='0px 20px'  margin='50px 5% 24px 5%' fontSize='28px' > Detalles de la compra</Typography>

                <div className='mb-2 mt-2 row' style={{margin:'0px 0%'}}>
                    <div className="col-md-4" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' ml='5px' mt='12px' mb='12px' fontSize='16px' >Descripción</Typography>
                    </div>
                    <div className="col-md-8" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' mt='12px' mb='12px' fontSize='16px' > {DataTrasferencia.description}</Typography>
                    </div>

                    <div className="col-md-4" style={{ background: ' #fae2a5 ', border: 'solid 2px white' }}>
                        <Typography textAlign='left' ml='5px' mt='12px' mb='12px' fontSize='16px' >Fecha y hora</Typography>
                    </div>
                    <div className="col-md-8" style={{ background: ' #fae2a5 ', border: 'solid 2px white' }}>
                        <Typography textAlign='left' mt='12px' mb='12px' fontSize='16px' >{formatDate(PedidoCliente.fecha)}</Typography>
                    </div>
                </div>

                <Typography textAlign='left' fontWeight='500 !important' padding='0px 22px' margin='24px 5%' fontSize='28px' >  Pasos para realizar el pago</Typography>

                <div style={{ margin: '0px 3% ' }} className='mb-4 row'>
                    <div className="col-md-6" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography ml='26px' mt='12px' fontWeight='600 !important' fontSize='14px' >
                            Desde BBVA BANCOMER
                        </Typography>
                        <Typography textAlign='left' ml='26px' mt='6px' mb='6px' fontSize='14px' >1. Dentro del menú de “Pagar” seleccione la opción
                            “De Servicios” e ingrese el siguente “Núnero de
                            convenio CIE”
                        </Typography>
                        <div style={{ marginLeft: '13px' }} className='row'>
                            <div className='col-8'>
                                <Typography fontWeight='600 !important' fontSize='14px'>Número de convenio CIE:  </Typography>
                            </div>
                            <div className='col-4'>
                                <Typography fontSize='14px'>{DataTrasferencia.payment_method.agreement}</Typography>
                            </div>
                        </div>
                        <Typography textAlign='left' ml='26px' mt='6px' mb='12px' fontSize='14px' >2. Ingrese los datos de registro para concluir con la operación.</Typography>
                        <div style={{ marginLeft: '13px' }} className='row'>
                            <div className='col-3'>
                                <Typography mt='5px' fontWeight='600 !important' fontSize='14px'>Referencia: </Typography>
                            </div>
                            <div className='col-9'>
                                <Typography mt='5px' fontSize='14px'>{DataTrasferencia.payment_method.name}</Typography>
                            </div>
                            <div className='col-3'>
                                <Typography mt='5px' fontWeight='600 !important' fontSize='14px'>Importe: </Typography>
                            </div>
                            <div className='col-9'>
                                <Typography mt='5px' fontSize='14px'>{formatearComoMonedaMXN(PedidoCliente.total)} MXN</Typography>
                            </div>
                            <div className='col-3'>
                                <Typography mt='5px' fontWeight='600 !important' fontSize='14px'>Concepto: </Typography>
                            </div>
                            <div className='col-9'>
                                <Typography mt='5px' mb='5px' fontSize='14px'>Compra de produto X</Typography>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6" style={{ background: ' #fbf4da', border: 'solid 2px white' }}>
                        <Typography textAlign='left' mt='12px' mb='6px' fontSize='14px' > Descripción de la compra realizada</Typography>
                        <Typography textAlign='left' mt='6px' mb='12px' fontSize='14px' >
                            1. Ingresa a la selección de transferencias y pagos o pagos a otros
                            bancos y proporciona los datos de transferencia:
                        </Typography>
                        <div style={{ display: 'flex' }}>
                            <Typography mt='5px' width='34%' fontWeight='600 !important' fontSize='14px'>Beneficiario: </Typography>
                            <Typography mt='5px' width='66%' fontSize='14px'>COLUMBA</Typography>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <Typography mt='5px' width='34%' fontWeight='600 !important' fontSize='14px'>Banco destino: </Typography>
                            <Typography mt='5px' width='66%' fontSize='14px'>BBVA Bancomer</Typography>

                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography mt='5px' width='34%' fontWeight='600 !important' fontSize='14px'>Clabe:  </Typography>
                            <Typography mt='5px' width='66%' mb='5px' fontSize='14px'>{DataTrasferencia.payment_method.clabe}</Typography>
                        </div>

                        <div style={{ display: 'flex' }}>
                            <Typography mt='5px' width='34%' fontWeight='600 !important' fontSize='14px'>Concepto de pago:   </Typography>
                            <Typography mt='5px' width='66%' mb='5px' fontSize='14px'>1234567898765432100123456789</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography mt='5px' width='34%' fontWeight='600 !important' fontSize='14px'>Referencia:  </Typography>
                            <Typography mt='5px' width='66%' mb='5px' fontSize='14px'>{DataTrasferencia.payment_method.name}</Typography>
                        </div>
                        <div style={{ display: 'flex' }}>
                            <Typography mt='5px' width='34%' fontWeight='600 !important' fontSize='14px'>Importe: </Typography>
                            <Typography mt='5px' width='66%' mb='12px' fontSize='14px'>{formatearComoMonedaMXN(PedidoCliente.total)} MXN</Typography>
                        </div>
                    </div>
                </div>

                <Typography width='100%' textAlign='center' fontSize='14px'>Si tiene dudas comunícate a COLUMBA al teléfono <Typography display='inline' fontWeight={600}>{TodoDataService.GetNumberCall()}</Typography>  o al correo <Typography display='inline' fontWeight={600}>{TodoDataService.GetEmailCall()} </Typography>
                </Typography>
                <img src={Foother_Transferencia} onLoad={generatePdf}  alt='Foother_Transferencia' width='100%' />

            </div>

            <Box mb='24px'>
                <RadioGroupImagen WidthImagen='50%' Origen='Row' Name='TipoPdf' Width='md' OnChange={OnChangePago} JustifyContent='space-between' Content={ContentRadion} />

            </Box>


        </Box>
    )
}

export default PagoTransferencia;
