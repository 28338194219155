import Conmemoraciones from "../pages/Conmemoraciones";
import Home from "../pages/Home";
import SetsFunerarios from "../pages/Sets_funerarios";
import PlanesFuturos from "../pages/Planes_futuros";
import Seguimiento from "../pages/Seguimiento";




const routes = {
    Principales:
    [
        {id:1,name:'COLUMBA',path:'/',component:Home},
        {id:2,name:'SETS FUNERARIOS',path:'/SetsFunerarios',component:SetsFunerarios},
        {id:3,name:'PLANES A FUTURO',path:'/PlanesFuturo',component:PlanesFuturos},
        {id:4,name:'CONMEMORACIÓN',path:'/Conmemoraciones',component:Conmemoraciones},
        {id:5,name:'SEGUIMIENTO',path:'/Seguimiento',component:Seguimiento},
    ],
    Secundarias:
    [
        {id:6,name:'CONMEMORACION DETALLE',path:'/Conmemoraciones/:id',component:Conmemoraciones},
        {id:7,name:'SETS FUNERARIOS COBERTURA',path:'/SetsFunerarios/Cobertura/:idcobertura',component:SetsFunerarios},
        {id:8,name:'SETS FUNERARIOS PERZONALIZA',path:'/SetsFunerarios/PersonalizaSet',component:SetsFunerarios},
        {id:9,name:'SETS FUNERARIOS DETALLLE',path:'/SetsFunerarios/:id',component:SetsFunerarios},
        {id:10,name:'SEGUIMIENTO DETALLE',path:'/Seguimiento/:folio/:appaterno',component:Seguimiento},
    ],
}


export default routes