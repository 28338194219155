import axios from 'axios';
class TodoDataService {



  GetHost() {
    const Host = 'https://clmb.proximasistemas.com.mx/';
    // const Host = 'https://192.168.1.10:80/';
    // const Host = 'https://192.168.1.160/';
    return Host;
  }
  GetHost2() {
    const Host = 'https://clmb.proximasistemas.com.mx';
    // const Host = 'https://192.168.1.10:80';
    // const Host = 'https://192.168.1.160';

    return Host;
  }

  GetHostRestApi() {
    const Host = 'https://preprodcolumba.prophet.mx/api';
    return Host;
  }



  GetNumberPhone = () => {
    const Phone = '+5217227846600';

    return "https://wa.me/" + encodeURIComponent(Phone) + "?text=Estoy%20interesado%20en%20contratar";
  }

  GetNumberCall = () => {
    const Phone = '7227846600';

    return Phone;
  }

  GetEmailCall = () => {
    const Phone = 'contacto@columbafunerales.com';

    return Phone;
  }

  GetEmail() {
    const Email = 'contacto@columbafunerales.com'
    return 'mailto:' + encodeURIComponent(Email) + "?subject=Contratacion%20de%20servicio&body=Estoy%20interesado%20en%20contratar"
  }



  capitalize = str =>
    str.split(' ')
      .map(([first, ...rest]) => [first.toUpperCase(), ...rest].join(''))
      .join(' ');


  capitalizeEachWord = (text) => {
    const words = text.toLowerCase().split(" ");
    const wordsCapitalized = words.map(word => this.capitalize(word));
    return wordsCapitalized.join(" ");
  }


  ValidarFolioCliente = async (OnclickActionData) => {

    axios.defaults.headers.common["Authorization"] = OnclickActionData.Token;

    await axios.request(OnclickActionData.Url2 + OnclickActionData.Data.PedidoClienteFolio).then(response => {
      axios.request(OnclickActionData.Url3 + OnclickActionData.Data.PedidoClienteFolio + '/144').then(response => {
        OnclickActionData.Refresh({ Error: 'Ya existe una conmemeoracion para el folio ingresado' })
      }).catch(error => {
        OnclickActionData.Refresh({ PedidoCliente: response.data });
        axios.request(OnclickActionData.Url + OnclickActionData.Data.PedidoClienteFolio).then(response => {
          OnclickActionData.Refresh({ ValidatePedidoClienteFolio: true, OpenConmemoracion: false, DataFinado: { agnoDefuncion: response.data.agnoDefuncion, agnoNacimiento: response.data.agnoNacimiento, nombreCompleto: this.capitalizeEachWord(response.data.nombreCompleto), setFunerario: this.capitalizeEachWord(response.data.setFunerario), sexo: this.capitalizeEachWord(response.data.sexo) } });
        }).catch(error => {
          console.log(error);
          OnclickActionData.Refresh({ Error: error.response.data.errores })
        })
      })
    }).catch(error => {
      console.log(error);
      OnclickActionData.Refresh({ Error: error.response.data.errores.mensaje })
    })

    // await 
  }



  PeticionGet = async (Data) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    await axios.request(Data.Url).then(response => {
      Data.Save({ [Data.NameKey]: response.data });
    }).catch(error => {
      console.log(error);
      // OnclickActionData.Refresh({Error:error.response.data.errores.mensaje})
    })
  }


  ConvertBase64ToBlob = async (extencionFoto, foto) => {
    const blob = await fetch(extencionFoto + ',' + foto);
    return (URL.createObjectURL(await blob.blob()));
  }




  PeticionGetComemoraciones = async (Data) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    let ListConmemoracion = ''
    await axios.request(Data.Url1).then(response => {
      ListConmemoracion = response.data
    }).catch(error => {
      console.log(error);
    })

    const DataConmeracionCaratula = await Promise.all(
      ListConmemoracion.map(async (conmemoracion) => ({
        Conmemoracion: conmemoracion,
        Caratula: await this.consulto(Data.Url2 + conmemoracion.folioOrigen)
      }))
    )

    let DataConmemoracionCaratulaFilter = DataConmeracionCaratula.filter((element) => (element.Caratula !== undefined && element.Caratula.foto !== null && element.Caratula.extencionFoto !== null))
    let prueba = await Promise.all(DataConmemoracionCaratulaFilter.map(async (element) => ({
      Caratula:
      {
        agnoDefuncion: element.Caratula.agnoDefuncion,
        agnoNacimiento: element.Caratula.agnoNacimiento,
        foto: await this.ConvertBase64ToBlob(element.Caratula.extencionFoto, element.Caratula.foto),
        nombreCompleto: this.capitalizeEachWord(element.Caratula.nombreCompleto),
        setFunerario: this.capitalizeEachWord(element.Caratula.setFunerario),
        sexo: element.Caratula.sexo,
      }
      , Conmemoracion: element.Conmemoracion
    })))
    Data.Save({
      [Data.NameKey]: prueba
    });

  }






  peticionPost = async (Data) => {
    let DataC = { folioOrigen: Data.Conmemoracion.FolioOrigen, operacionOrigen: Data.Conmemoracion.OperacionOrigen, folioReferencia: '', operacionReferencia: '', mensaje: Data.Conmemoracion.Mensaje, foto: Data.Conmemoracion.FotoVideo, extencionFoto: Data.Conmemoracion.ExtencionFoto, video: Data.Conmemoracion.Video, extencionVideo: Data.Conmemoracion.ExtencionVideo }
    console.log(DataC)
    axios.defaults.headers.common["Authorization"] = Data.Token;
    await axios.post(Data.Url, DataC).then(response => {
      Data.Refresh();
    }).catch(error => {
      Data.SetError({ ConmemoracioError: error });
      console.log(error);
    });
  }


  PostMemoria = async (Data) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    await axios.post(Data.Url, Data.Memoria).then(response => {
      axios.request(this.GetHostRestApi() + '/Conmemoraciones/memorias/' + Data.Memoria.folio).then(response => {
        Data.Refresh(response.data, 'DataNuevaMemoria', 'Conmemoracion');
        Data.Close();
      }).catch(error => {
        console.log(error);
      })
    }).catch(error => {
      console.log(error);
    });
  }


  consulto = async (url) => {

    let result = '';
    await axios.request(url).then(response => {
      result = response
      console.log(response);
    }).catch(error => {
      console.log(error)
    })

    return result.data;
  }


  ValidarCupon = async (Data) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    const respuesta = await axios.request(Data.Url + Data.Data.FolioCupon)
    if (respuesta.data.cantidad !== 0) {
      if (respuesta.data.tipo === 'P') {
        Data.SetError({ FormPago: { ...Data.Data, CuponValido: true, Descuento: Data.Total * respuesta.data.cantidad / 100 } });


      }
      if (respuesta.data.tipo === 'I') {
        Data.SetError({ FormPago: { ...Data.Data, CuponValido: true, Descuento: respuesta.data.cantidad } });
      }

    }
    else {
      Data.SetError({ FormPago: { ...Data.Data, CuponValido: false, Descuento: 0, Error: ['Verifica tu cupón!'] } });
    }
  }


  GetNuevaMemoria = async (Data, Caratula, Folio) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    Data.Refresh({ DataNuevaMemoria: { Conmemoracion: '', Caratula: Caratula } });
    Data.Close();
    await axios.get(Data.Url + Folio).then(response => {
      Data.Refresh({ DataNuevaMemoria: { Conmemoracion: response.data, Caratula: Caratula } });
    }).catch(error => {
      console.log(error);
    });
  }


  GetNuevaMemoriaCompartir = async (Data) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    let datacaratula = await this.PeticionGetComemoracionesNuevaMemoriaCompartir({ Token: Data.Token, Url1: Data.Url1, Url2: Data.Url2 });
    // Data.Refresh({ DataConmemoracion: datacaratula});
    Data.Refresh({ NuevaMemoria: true ,DataConmemoracion: datacaratula, Compartir: true, DataNuevaMemoria: { Conmemoracion: '', Caratula: datacaratula.find((element) => element.Conmemoracion.folio === Data.Folio).Caratula } });
    // Data.Close();
    console.log(datacaratula.find((element) => element.Conmemoracion.folio === Data.Folio).Caratula)
    await axios.get(Data.Url3 + Data.Folio).then(response => {
      let Memoria = response;
      Data.Refresh({ NuevaMemoria: true ,DataConmemoracion: datacaratula,  Compartir: true, DataNuevaMemoria: { Conmemoracion: Memoria.data, Caratula: datacaratula.find((element) => element.Conmemoracion.folio === Data.Folio).Caratula } });
    }).catch(error => {
      console.log(error);
    });
  }


  PeticionGetComemoracionesNuevaMemoriaCompartir = async (Data) => {
    axios.defaults.headers.common["Authorization"] = Data.Token;
    let ListConmemoracion = ''
    await axios.request(Data.Url1).then(response => {
      ListConmemoracion = response.data
    }).catch(error => {
      console.log(error);
    })

    const DataConmeracionCaratula = await Promise.all(
      ListConmemoracion.map(async (conmemoracion) => ({
        Conmemoracion: conmemoracion,
        Caratula: await this.consulto(Data.Url2 + conmemoracion.folioOrigen)
      }))
    )

    let DataConmemoracionCaratulaFilter = DataConmeracionCaratula.filter((element) => (element.Caratula !== undefined && element.Caratula.foto !== null && element.Caratula.extencionFoto !== null))
    let prueba = await Promise.all(DataConmemoracionCaratulaFilter.map(async (element) => ({
      Caratula:
      {
        agnoDefuncion: element.Caratula.agnoDefuncion,
        agnoNacimiento: element.Caratula.agnoNacimiento,
        foto: await this.ConvertBase64ToBlob(element.Caratula.extencionFoto, element.Caratula.foto),
        nombreCompleto: this.capitalizeEachWord(element.Caratula.nombreCompleto),
        setFunerario: this.capitalizeEachWord(element.Caratula.setFunerario),
        sexo: element.Caratula.sexo,
      }
      , Conmemoracion: element.Conmemoracion
    })))

    return prueba;

  }





  PostNuevoservicio = async (Data) => {
    // let Respuesta='';
    axios.defaults.headers.common["Authorization"] = Data.Token;
      await axios.post(this.GetHostRestApi() + '/PedidoCliente', Data.DataResponsable).then(response => {
        let Respuesta = response.data;
        let DataFinado = Object.assign(Data.DataFinado, { folioPedido: Respuesta.folio })
        axios.post(this.GetHostRestApi() + '/Finado', DataFinado).then(response => {
          Data.Close();
        }).catch(error => {
          console.log(error);
        })
        Data.Refresh('FormPago', 'PedidoCliente', Respuesta);
      }).catch(error => {
        console.log(error);
      });
  }


  // PostAnticipoCliente = async (Data, Token, OnClickClose) => {
  //   axios.defaults.headers.common["Authorization"] = Token;
  //   await axios.post(this.GetHostRestApi() + '/Anticipos', Data).then(response => {
  //     PutAutorizar(Data.FolioOrigen,OnClickClose);
  //   }).catch(error => {
  //     console.log(error);
  //   });
  // }


  // PutAutorizar = async (Folio, OnClickClose) => {
  //   axios.defaults.headers.common["Authorization"] = Token;
  //   await axios.put(this.GetHostRestApi() + '/autorizar', Folio).then(response => {
  //     OnClickClose();
  //   }).catch(error => {
  //     console.log(error);
  //   });            
  // } 


  PostAnticipoCliente = async (Data, Token, OnClickClose,SetError) => {
    try {
      axios.defaults.headers.common["Authorization"] = Token;
      await axios.post(this.GetHostRestApi() + '/Anticipos', Data);
      await this.PutAutorizar(Data.FolioOrigen, Token, OnClickClose);
    } catch (error) {
      SetError(true);
      console.error("Error en PostAnticipoCliente:", error);
    }
  };
  
  PutAutorizar = async (Folio, Token, OnClickClose,SetError) => {
    try {
      axios.defaults.headers.common["Authorization"] = Token;
      await axios.put(this.GetHostRestApi() + '/pedidocliente/autorizar/'+ Folio);
      OnClickClose();
    } catch (error) {
      SetError(true);
      console.error("Error en PutAutorizar:", error);
    }
  };

  PostGenerarCliente = async (DataResponsable,Save,Error,Close,Token) => {
    let datapost={codEstab:'2',nombre:DataResponsable.Nombre,apPaterno:DataResponsable.ApellidoPaterno,apMaterno:DataResponsable.ApellidoMaterno,tel1:DataResponsable.TelefonoPrimario,tel2:DataResponsable.TelefonoSecundario,email:DataResponsable.Email}
    axios.defaults.headers.common["Authorization"] = Token;
    await axios.post(this.GetHostRestApi() + '/clientes', datapost).then(response => {
      Save('FormPago','KeyCliente',response.data.codCte);
      Close(); 
    }).catch(error => {
      console.log(error);
      let errores = [];
      errores.push(error.response.data.errores.mensaje)
      Error(errores);
    });
  }
}
export default new TodoDataService();
