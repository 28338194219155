// Importación de la función useNavigate desde react-router-dom para la navegación en la aplicación.
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'

// Importación de componentes de terceros desde Material-UI
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ListItemIcon from '@mui/material/ListItemIcon';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';


// Importación del servicio TodoDataService desde la ruta '../../hooks/services'
import TodoDataService from '../../hooks/services';

// Importación de archivos estáticos
import LogoHeader from '../../assets/Imagenes/LogoHeader.png';

// Definición de temas personalizados para estilos
const theme = createTheme();
theme.typography.body1 = {
  fontSize: '12px',
  fontWeight: 'bold !important',
  fontFamily: 'Itaca',
  '@media (max-width:1200px)': {
    fontSize: '11px',
    fontWeight: 'bold !important'
  },
};

const theme2 = createTheme();
theme2.typography.body1 = {
  fontSize: '12px',
  fontWeight: 'bold !important',
  fontFamily: 'Itaca',
  '@media (max-width:1200px)': {
    fontSize: '11px',
    fontWeight: 'bold !important'
  },
};

const drawerWidth = 240;

const HeaderColumba = ({ ListLink }) => {
  // Estado para controlar la apertura y cierre del menú lateral en dispositivos móviles
  const [mobileOpen, setMobileOpen] = useState(false);
  const [Ubicacion, setUbicacion] = useState('');
  const Raiz = TodoDataService.GetHost2();
  const [padding, setPadding] = useState(0);
  const [WidthMenu, setWidthMenu] = useState(1080);
  const [WidthLateral, setWidthLateral] = useState(0);



  // Función para alternar la apertura y cierre del menú lateral en dispositivos móviles
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  // Función para la navegación entre páginas de la aplicación
  const navigate = useNavigate();

  // Función para manejar el clic en los elementos del menú y redireccionar a las páginas correspondientes
  const handleClick = (link) => {
    navigate(link);
  };


  useEffect(() => {
    const handleResize = () => {

      const containerWidth = window.innerWidth * 0.8;
      if( containerWidth >1080){
        
      const paddingValue = Math.max(0, (containerWidth - 1080) / 2);
      setPadding(paddingValue);
      setWidthMenu(containerWidth - 2*paddingValue);
      setWidthLateral(window.innerWidth * 0.1+paddingValue);
      }
      else{
        setWidthLateral((window.innerWidth - 1080)/2);
      }
      
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Call it initially to set the correct padding

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);



  // Contenido del menú lateral
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ color: 'white', margin: '0px !important', padding: '0px !important', backgroundColor: '#D14081', textAlign: 'center' }}>
      <List>
        {ListLink.map((item) => (
          item.name === 'COLUMBA'
            ?
            <Box sx={{ display: 'grid', justifyContent: 'center', alignContent: 'center', height: '100%', backgroundColor: '#D14081' }}>
              <Button disableRipple onClick={() => handleClick('/')} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }}>
                <img width='80%' src={LogoHeader} alt='Logo Paloma' />
              </Button>
            </Box>
            :
            <ListItem key={item.name}>
              <ListItemButton disablePadding disableRipple onClick={() => { if (Ubicacion !== Raiz + item.path) { handleClick(item.path); setUbicacion(item.name); } else { window.location.reload(); } }} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" }, textAlign: 'center' }}>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </ListItem>
        ))}
        <ListItem disablePadding>
          <ListItemButton disableRipple onClick={() => window.open(TodoDataService.GetNumberPhone(), "_blank")} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" }, textAlign: 'center' }}>
            <ListItemIcon>
              <WhatsAppIcon sx={{ color: 'white', height: '80%', '@media (max-width:1060px)': { height: '50%' } }} />
            </ListItemIcon>
            <ListItemText primary={TodoDataService.GetNumberCall() }> </ListItemText>
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding onClick={() => window.open(TodoDataService.GetEmail(), "_blank")} sx={{}}>
          <ListItemButton disableRipple sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" }, textAlign: 'center' }}>
            <ListItemIcon>
              <MailOutlineIcon sx={{ color: 'white', height: '80%', '@media (max-width:1060px)': { height: '50%' } }} />
            </ListItemIcon>
            <ListItemText primary={TodoDataService.GetEmailCall()}> </ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );


  useEffect(() => {
    const OptenerUrl = () => {
      setUbicacion(window.location.href);
    }
    OptenerUrl();
  }, [window.location.href]);

  return (

    <Box sx={{ height: '75px', margin: '0px !important', padding: '0px !important', backgroundColor: '#D14081', display: 'flex', textAlign: 'center' }}>
      {/* Espacio en blanco a la izquierda del encabezado */}
      <Box sx={{ width: WidthLateral + 'px' , backgroundColor: '#D14081' }}></Box>
      <Box sx={{ display: 'flex',margin:'0px 0px !important', padding:`0px 0px 0px 0px  !important`, width:`${WidthMenu}px !important` }}>
        {/* Logo de la aplicación */}
        <Box  sx={{ display: { xs: 'none', md: 'block' }, width: '10%' }}>
          <Box sx={{ display: 'grid', justifyContent: 'flex-start', alignContent: 'center', height: '100%', backgroundColor: '#D14081' }}>
            <Button disableRipple onClick={() => handleClick('/')} sx={{padding:'0px',margin:'0px', "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }}>
              <img width='90%' src={LogoHeader} alt='Logo Paloma' />
            </Button>
          </Box>
        </Box>

        {/* Barra lateral de color amarillo */}
        <Box sx={{ display: { xs: 'none', md: 'block' }, backgroundColor: '#FFEC00', padding: '1% 0% 1% 0%', margin: '0px', borderRadius: '50% 0% 0% 50%', width: '5%' }} ></Box>

        {/* Enlaces de navegación en la barra superior */}
        <Box sx={{ display: { xs: 'none', md: 'block' }, width: { xs: '75%', lg: '75%', xl: '70%' }, paddingTop: '25px', backgroundColor: '#FFEC00' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
            {ListLink.map((item) => (
              <Button disableRipple onClick={() => { if (Ubicacion !== Raiz + item.path) { handleClick(item.path); } else { window.location.reload(); } }} key={item.name} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" }, outline: 'none !important', color: Ubicacion.includes(Raiz + item.path) ? item.path === '/' && Ubicacion !== Raiz + item.path ? '#3F3F3F' : '#D14081' : '#3F3F3F', fontSize: '14px', '&:hover': { color: 'white' } }}>
                <ThemeProvider theme={theme}>
                  <Typography variant='body1'>{item.name}</Typography>
                </ThemeProvider>
              </Button>
            )
            )}
          </Box>
        </Box>

        {/* Iconos de WhatsApp y correo electrónico en la barra superior */}
        <Box  sx={{ display: { xs: 'none', md: 'block' }, width: { lg: '15%', xl: '15%' }, paddingTop: '7px', backgroundColor: '#FFEC00', }}>
          <Box  sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button disableRipple component='' onClick={() => window.open(TodoDataService.GetNumberPhone(), "_blank")} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" }, display: 'flex', flexDirection: 'row', }}>
                  <WhatsAppIcon sx={{ fontSize: '14px', color: '#D14081', height: '80%', '@media (max-width:1060px)': { height: '50%' } }} />
                  <ThemeProvider theme={theme2}>
                    <Typography variant='body1' sx={{ display: { xs: 'none', md: 'block' }, color: '#D14081' }}>{TodoDataService.GetNumberCall()}</Typography>
                  </ThemeProvider>
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                <Button disableRipple onClick={() => window.open(TodoDataService.GetEmail(), "_blank")} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" }, display: 'flex', flexDirection: 'row' }}>
                  <MailOutlineIcon sx={{ fontSize: '14px', color: '#D14081', height: '80%', '@media (max-width:1060px)': { height: '50%' } }} />
                  <ThemeProvider theme={theme2}>
                    <Typography variant='body1' sx={{ display: { xs: 'none', md: 'block' }, color: '#D14081' }}>{TodoDataService.GetEmailCall()}</Typography>
                  </ThemeProvider>
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        {/* Barra superior en dispositivos móviles */}
        <Box sx={{ width: '100%', margin: '0px !important', padding: '0px !important', backgroundColor: '#D14081', display: { xs: ' block', md: 'none' } }} >
          <Box sx={{ display: 'flex', height: '100%', width: '100%', justifyConten: 'center' }} >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '60%', margin: '2% 0%' }}>
              <Button disableRipple onClick={() => handleClick('/')} sx={{ "&.MuiButtonBase-root:hover": { bgcolor: "transparent" } }} >
                <img src={LogoHeader} alt='Logo Paloma' />
              </Button>
            </Box>
            <Box sx={{ backgroundColor: '#FFEC00', padding: '0%', margin: '0px', borderRadius: '50% 0% 0% 50%', height: '100%', width: { xs: '20%', sm: '12.5%' } }} ></Box>
            <Box sx={{ width: { xs: '20%', sm: '27.5%' }, backgroundColor: '#FFEC00', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ margin: '0px !important', padding: '0px !important', display: { xs: ' block', md: 'none' } }}>
                <MenuIcon sx={{ color: '#D14081', fontSize: '45px', margin: '0px !important', padding: '0px !important' }} />
              </IconButton>
            </Box>
          </Box>
        </Box>


      </Box>

      {/* Espacio en blanco a la derecha del encabezado */}
      <Box sx={{ width: WidthLateral + 'px' , backgroundColor: '#FFEC00' }}></Box>


      {/* Navegación lateral en dispositivos móviles */}
      <nav style={{ zIndex: 2000, margin: '0px !important', padding: '0px !important', paddingRight: '0px !important' }}>
        <Drawer variant="temporary" open={mobileOpen} onClose={handleDrawerToggle} ModalProps={{ keepMounted: true }}
          sx={{ top: '76px', margin: '0px !important', padding: '0px !important', display: { xs: 'block', md: 'none' }, '& .MuiDrawer-paper': { margin: '0px !important', padding: '0px !important', backgroundColor: '#D14081', boxSizing: 'border-box', width: drawerWidth } }}>
          {drawer}
        </Drawer>
      </nav>
    </Box>
  );
}

export default HeaderColumba;